import React, { useState } from 'react'
import "./SideMenu.css";
const SideMenu = ({ sourceId, caption }) => {

    return (
        <div className="sidemenuItems">
            <div ><img src={require('../../../Images/logo.png')} alt="test" style={{ width: '40px', height: '40px', padding: '5px' }} />
                <div>Chats</div>
            </div>
            <div ><img src={require('../../../Images/chats.png')} alt="test" style={{ width: '40px', height: '40px', padding: '5px' }} />
                <div>Chats</div>
            </div>
            <div ><img src={require('../../../Images/contacts.png')} alt="test" style={{ width: '40px', height: '40px', padding: '5px' }} />
                <div>Chats</div>
            </div>
            <div ><img src={require('../../../Images/settings.png')} alt="test" style={{ width: '40px', height: '40px', padding: '5px' }} />
                <div>Chats</div>
            </div>
        </div>
    )
}

export default SideMenu