import React, { useEffect, useState } from "react";
import "./Header.css";
import { useNavigate } from "react-router-dom"
import { MdMenu } from "react-icons/md";
import Profile from "../Profile/Profile";
import SecuritySettings from "../SecuritySettings/SecuritySettings";
const Header = ({ IsMobileView, userinfochat, IsUserinfoView, IsMessageView, IsCharbarView, onBackButton }) => {

    const [isProfile, setIsProfile] = useState(false);
    const [isProfileSecurity, setIsProfileSecurity] = useState(false);
    const profileEvent = () => {
        if (isProfile) {
            setIsProfile(false);
        } else {
            setIsProfile(true);
        }
    }

    const onProfileEvent = () => {
        setIsProfile(false);
        setIsProfileSecurity(true);

    }

    const profileSecuritySettingsEvent = (event) => {
        if (isProfileSecurity) {
            setIsProfileSecurity(false);
        } else {
            setIsProfileSecurity(true);
        }
        console.log("enter--", isProfileSecurity);
    }

    const onProfileSecuritySettingsEvent = (event) => {
        setIsProfileSecurity(false);
        setIsProfile(true);
    }


    const navigate = useNavigate()
    const handleLeaveChat = () => {
        localStorage.removeItem("userName")
        localStorage.removeItem('login');
        navigate("/", { replace: true });


        localStorage.removeItem('uname');
        localStorage.removeItem('ubusinessname');
        localStorage.removeItem('uph_number');
        localStorage.removeItem('uemail');
        localStorage.removeItem('uaddress');
        localStorage.removeItem('ucity');
        localStorage.removeItem('ustate');
        localStorage.removeItem('ucountry');
        localStorage.removeItem('utimezone');
        localStorage.removeItem('active');
        localStorage.removeItem('selectedNumber');
        localStorage.removeItem('selectedName');



        window.location.reload()
    }
    useEffect(() => {
        window.addEventListener("resize", resize);
        setIsHidden(window.innerWidth <= 768);
        setWhiteSpaceTargetStyle({
            zIndex: isHidden ? 0 : 990,
            backgroundColor: isHidden
                ? "rgba(0,0,0,0)"
                : "rgba(100,100,100,0.3)",
        });

        return () => {
            window.removeEventListener('resize', resize);
        }
    }, []);

    const [isHidden, setIsHidden] = useState(true);
    const [isShowMenu, setIsShowMenu] = useState(true);
    const [whiteSpaceTargetStyle, setWhiteSpaceTargetStyle] = useState({
        zIndex: 0,
        backgroundColor: "rgba(0,0,0,0)",
    });

    const resize = () => {
        setIsHidden(window.innerWidth <= 768 ? true : false);
    };

    const toggleMenu = () => {
        if (isHidden) {
            showMenu();
        } else {
            hideMenu();
        }
    };

    const classList = () => {
        if (isHidden) {
            return "menu hidden";
        }
        return "menu";
    };

    const showMenu = () => {
        if (isHidden) {
            setIsHidden(false);
            setIsShowMenu(false);
            setWhiteSpaceTargetStyle({
                zIndex: 990,
                backgroundColor: "rgba(100,100,100,0.3)",
            });
        }
    };

    const hideMenu = () => {
        if (!isHidden) {
            setIsHidden(true);
            setIsShowMenu(true);
            setWhiteSpaceTargetStyle({
                zIndex: 0,
                backgroundColor: "rgba(0,0,0,0)",
            });
        }
    };

    const onMenuevent = (value) => {
        if (value === 1) {
            if (IsMobileView) {
                toggleMenu();
            }
        } else if (value === 2) {
            if (IsMobileView) {
                toggleMenu();
            }
            profileEvent();
        }
    };

    return (
        <div className="headermain" >
            <div className="headeritems">

                {/*  <div className="menu-button" onClick={toggleMenu}>
                    <MdMenu size="2em" />
                </div>*/}
                <div className={!IsMobileView ? "menu" : classList()} id="menu">

                    <div className="menuiconchat">
                        <img alt="test" onClick={() => onMenuevent(1)} />
                        <span hidden={!IsMobileView && isShowMenu}>chats</span>
                    </div>

                    <div className="menuiconpersion" onClick={() => onMenuevent(2)}>
                        <img alt="test" />
                        <span hidden={!IsMobileView && isShowMenu}>Profile</span>
                    </div>



                    <br />
                </div>
                {IsMobileView &&
                    <div style={{ display: "flex" }}>
                        <div
                            id="menu-whitespace-target"
                            hidden={isHidden && IsMobileView}
                            onClick={hideMenu}
                            style={whiteSpaceTargetStyle}
                        ></div>
                    </div>}

                {(IsMobileView && IsMessageView)
                    && <img className="backbuttontochatbar" src={require('../../../Images/back.png')} alt="test" onClick={() => onBackButton(1)} />}



                {(IsMobileView && IsMessageView)
                    && <img className="infobuttontochatbar" src={require('../../../Images/information.png')} alt="test" onClick={userinfochat} />}
                {/*
                {(!IsMobileView && IsMessageView)
                    && <img className="mobilemenu-logo" src={require('../../../Images/bulkwiselogo.png')} alt="test" />}
*/}

                {(!IsMobileView && IsMessageView)
                    && <img className="headeritems_outlog" src={require('../../../Images/logout.png')} alt="test" onClick={handleLeaveChat} />}


                {(IsMobileView && IsUserinfoView)
                    && <img className="backbuttontochatbar" src={require('../../../Images/back.png')} alt="test" onClick={() => onBackButton(2)} />}

                {/*  {(!IsMobileView && IsUserinfoView)
                    && <img className="mobilemenu-logo" src={require('../../../Images/bulkwiselogo.png')} alt="test" />}
*/}
                {(!IsMobileView && IsUserinfoView)
                    && <img className="headeritems_outlog" src={require('../../../Images/logout.png')} alt="test" onClick={handleLeaveChat} />}



                {(IsMobileView && IsCharbarView)
                    && <img className="mobilemenu-button" src={require('../../../Images/hamburger.png')} alt="test" onClick={() => toggleMenu()} />}

                {(IsCharbarView)
                    && <img className="mobilemenu-logo" src={require('../../../Images/bulkwiselogo.png')} alt="test" />}


                {(IsCharbarView)
                    && <img className="headeritems_outlog" src={require('../../../Images/logout.png')} alt="test" onClick={handleLeaveChat} />}


                {(isProfile) &&
                    <Profile profileEvent={profileEvent} onProfileEvent={onProfileEvent} />}
                {(isProfileSecurity) &&
                    <SecuritySettings profileSecuritySettingsEvent={profileSecuritySettingsEvent} onProfileSecuritySettingsEvent={onProfileSecuritySettingsEvent} />}

            </div>
        </div>
    )
}
export default Header;