import {BrowserRouter, Routes, Route} from "react-router-dom"
import Home from "./components/Home"
import Chats from "./pages/Components/Chats/Chats";
import socketIO from "socket.io-client"
import "@fontsource/poppins"; // Defaults to weight 400
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/400-italic.css"; // Specify weight and style

const socket = socketIO.connect("https://wabadev.bulkwise.in")
function App() {
  return (
    <BrowserRouter>
        <div>
          <Routes>
            <Route path="/" element={<Home socket={socket}/>}></Route>
                  <Route path="/chat" element={<Chats socket={socket}/>}></Route>
          </Routes>
    </div>
    </BrowserRouter>
    
  );
}

export default App;