import React from 'react';
import DateTimeDisplay from './DateTimeDisplay';
import { useCountdown } from './useCountdown';

const ExpiredNotice = () => {
    return (
        <div style={{ display: "flex" }}>

            <DateTimeDisplay value={"00"} />
            <p>:</p>
            <DateTimeDisplay value={"00"} />
            <p>:</p>
            <DateTimeDisplay value={"00"} />

        </div>
    );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
        <div style={{ display: "flex" }}>
           
            <DateTimeDisplay value={hours} />
            <p>:</p>
            <DateTimeDisplay value={minutes} />
            <p>:</p>
            <DateTimeDisplay value={seconds} />

        </div>
    );
};

const CountdownTimer = ({ targetDate }) => {
    const [days, hours, minutes, seconds] = useCountdown(targetDate);

    if (days + hours + minutes + seconds <= 0) {
        return <ExpiredNotice />;
    } else {
        return (
            <ShowCounter
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        );
    }
};

export default CountdownTimer;