import AsyncVideo from './AsyncVideo'
const PlayVideoPopup = ({ sourceId, toggleVideoPlay }) => {
    return (
        <div>
            <h1 style={{ textAlign: "center" }}>Play Video</h1>
            <AsyncVideo sourceId={sourceId}> </AsyncVideo>
            <div ><button onClick={toggleVideoPlay}>close</button></div>
        </div>
    );
}
export default PlayVideoPopup