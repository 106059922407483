import React from 'react';
import "./DateTimeDisplay.css";
const DateTimeDisplay = ({ value}) => {
    return (
        <div className="datetimedisplay" >
            <p>{value}</p>
        </div>
    );
};

export default DateTimeDisplay;