// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `


@media (min-width:320px) { /* smartphones, iPhone, portrait 480x320 phones */
    .chats_header {
        height: 8vh;
        width: 100%;
    }

    .chats_main {
        height: 92vh;
        width: 100%;
    }

}

@media (min-width:481px) { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    .chats_main {
        width: 100vw;
        display:flex;
    }

    .chats_main_menu {
        width: 5vw;
    }

    .chats_main_charbar {
        width: 25vw;
    }

    .chats_main_message {
        width: 50vw;
    }

    .chats_main_temp {
        width: 20vw;
    }
}



::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background: gray;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #D3D3D3;
}

    ::-webkit-scrollbar-thumb:hover {
        background: #A9A9A9;
    }

    ::-webkit-scrollbar-thumb:horizontal {
        background: #ffffff;
        border-radius: 10px;
    }
`, "",{"version":3,"sources":["webpack://./src/pages/Components/Chats/Chats.css"],"names":[],"mappings":";;;AAGA,2BAA2B,iDAAiD;IACxE;QACI,WAAW;QACX,WAAW;IACf;;IAEA;QACI,YAAY;QACZ,WAAW;IACf;;AAEJ;;AAEA,2BAA2B,2EAA2E;IAClG;QACI,YAAY;QACZ,YAAY;IAChB;;IAEA;QACI,UAAU;IACd;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,WAAW;IACf;;IAEA;QACI,WAAW;IACf;AACJ;;;;AAIA;IACI,WAAW;IACX,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;IAEI;QACI,mBAAmB;IACvB;;IAEA;QACI,mBAAmB;QACnB,mBAAmB;IACvB","sourcesContent":["\r\n\r\n\r\n@media (min-width:320px) { /* smartphones, iPhone, portrait 480x320 phones */\r\n    .chats_header {\r\n        height: 8vh;\r\n        width: 100%;\r\n    }\r\n\r\n    .chats_main {\r\n        height: 92vh;\r\n        width: 100%;\r\n    }\r\n\r\n}\r\n\r\n@media (min-width:481px) { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */\r\n    .chats_main {\r\n        width: 100vw;\r\n        display:flex;\r\n    }\r\n\r\n    .chats_main_menu {\r\n        width: 5vw;\r\n    }\r\n\r\n    .chats_main_charbar {\r\n        width: 25vw;\r\n    }\r\n\r\n    .chats_main_message {\r\n        width: 50vw;\r\n    }\r\n\r\n    .chats_main_temp {\r\n        width: 20vw;\r\n    }\r\n}\r\n\r\n\r\n\r\n::-webkit-scrollbar {\r\n    height: 4px;\r\n    width: 4px;\r\n    background: gray;\r\n}\r\n\r\n::-webkit-scrollbar-track {\r\n    background: #f1f1f1;\r\n}\r\n\r\n::-webkit-scrollbar-thumb {\r\n    background: #D3D3D3;\r\n}\r\n\r\n    ::-webkit-scrollbar-thumb:hover {\r\n        background: #A9A9A9;\r\n    }\r\n\r\n    ::-webkit-scrollbar-thumb:horizontal {\r\n        background: #ffffff;\r\n        border-radius: 10px;\r\n    }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
