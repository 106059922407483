import React, { useState } from "react";
import axios from 'axios'
const AsyncUploadImage = ({ togglePopupImage, socket }) => {
    var singleFileObj = [];
    var singleFileArray = [];
    const [singleFileObjValue, setSingleFileObjValue] = useState([]);
    const [singleFileArrayValue, setSingleFileArrayValue] = useState([]);
    const onImageChange = event => {
        for (let i = 0; i < event.target.files.length; i++) {
            singleFileObj.push(event.target.files[i]);
            singleFileArray.push(URL.createObjectURL(event.target.files[i]));
        }
        setSingleFileObjValue(singleFileObj);
        setSingleFileArrayValue(singleFileArray);
    };
    const shoot = async () => {
        for (let i = 0; i < singleFileObjValue.length; i++) {
            let formData = new FormData();
            formData.append('file', singleFileObjValue[i]);
            formData.append('messaging_product', 'whatsapp');
            try {
                let res = await axios.post('https://graph.facebook.com/v20.0/347188558485172/media', formData,
                    {
                        headers: {

                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer EAATwRdnZAHZAYBOzcofyZBXyLLS8fRQ7Vc810wfPdqF8SbrG9kWPA0lO0kUXGS6arVnQVXLJYwAZA87Sef3TWZAbvSVyYHPRay9ZBYdaCmauTC4m8z25PczbT0JYNMJZCBaBZADUgdMNGO9Yp9C4ayuokqqTH3H5DwuvWbuz82vGLz30xWWgQpJt2ZBLriaxoTSxNwwZDZD'
                        },
                    }

                );
                if (res.status === 200) {
                    socket.emit("media",
                        {
                            to: localStorage.getItem("selectedNumber"),
                            name: localStorage.getItem("selectedName"),
                            id: res.data.id,
                            type: 'image',
                            caption: ''
                        }
                    )
                }
            } catch (error) {
                console.error('Error uploading img : ', error);
            }
        }
        togglePopupImage();
    }
    const removeImage = (index) => {
        setSingleFileArrayValue([
            ...singleFileArrayValue.slice(0, index),
            ...singleFileArrayValue.slice(index + 1, singleFileArrayValue.length)
        ]);
    };
    return (
        <div>
            <div>
                <div>
                    <h1 style={{ textAlign: "center" }}>Select Image</h1>
                    <div style={{ textAlign: "center", display: "flex" }}><input type="file" name="myImage" multiple accept="image/png, image/jpeg" onChange={onImageChange} />
                        <div ><button onClick={shoot}>Send</button></div>
                        <div ><button onClick={togglePopupImage}>close</button></div>
                    </div>
                    <div style={{ minHeight:"50vh", minWidth:"50vh", maxHeight: "70vh", overflowY: "scroll" }}>
                        {singleFileArrayValue.length !== 0 &&
                            singleFileArrayValue.map((url, index) => (

                               /* (index === 0 || index % 3) === 0 ? <tr> : <td>*/


                                <div key={index} >
                                    <div className="img_background">
                                        <img className="img_content" src={url} alt="..." key={index} />
                                    </div>
                                    <span className="remove_img" onClick={() => removeImage(index)}  >
                                        X
                                    </span>
                                    </div>
                               
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
}
export default AsyncUploadImage