import React, { useEffect, useState } from "react";
const AsyncImage = ({ imgId, caption }) => {
    const [img, setImg] = useState();
    const fetchImage = async () => {
        setImg('https://wabadev.bulkwise.in/images/' + imgId);
    };
    useEffect(() => {
        fetchImage();
    }, []);
    return (
        <div>
            <img style={{ maxWidth: '400px', maxHeight:'400px' }} src={img} alt="icons" />
            <div className="message__recipient_inner">{caption === '' ? <div></div> : <p>{caption}</p>}</div>
        </div>
    );
}
export default AsyncImage