import React, { useEffect, useState } from "react";
const AsyncAudio = ({ sourceId, caption }) => {
    const [source, setSource] = useState();
    const fetchImage = async () => {
        setSource('https://wabadev.bulkwise.in/documents/' + sourceId);
    };
    useEffect(() => {
        fetchImage();
    }, []);
    return (
        <div>
            <a href={source} target="_blank">
                <img src={require('../../../Images/chat-sending-doc-image.png')} alt="test" style={{ width: '100px', height: '40px' }} />
            </a>
        </div>
    );
}
export default AsyncAudio