import React, { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { validate as validateEmail } from 'email-validator';
import "./Register.css";
import axios from 'axios'

const Register = ({ socket }) => {
    const navigate = useNavigate()
    const [businessName, setBusinessName] = useState("");
    const [name, setName] = useState("");
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [cpassword, setCPassword] = useState("");
    const [checkbox, setCheckbox] = useState(false);
    const [error, setError] = useState("");
    const [IsError, setIsError] = useState(false);
    const [IsMobileView, setIsMobileView] = useState(true);
    const [passwordShown, setPasswordShown] = useState(false);
    const [cpasswordShown, setCPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    };
    const toggleCPasswordVisiblity = () => {
        setCPasswordShown(cpasswordShown ? false : true);
    };
    const registration = async () => {
        const input = {
            "name": name,
            "businessname": businessName,
            "number": mobile,
            "email": email,
            "password": password
        }
        try {
            let res = await axios.post('https://wabadev.bulkwise.in/api/registration', input);
            if (res.status === 200) {
                console.log("RESPONSE RECEIVED:=== ", res.data);
                if (res.data.response === 'success') {
                    localStorage.setItem("userName", name)
                    socket.emit("newUser", { name, socketID: socket.id })
                    localStorage.setItem('login', name);
                   

                    localStorage.setItem('uname', res.data.uname);
                    localStorage.setItem('ubusinessname', res.data.ubusinessname);
                    localStorage.setItem('uph_number', res.data.uph_number);
                    localStorage.setItem('uemail', res.data.uemail);
                    localStorage.setItem('uaddress', res.data.address);
                    localStorage.setItem('ucity', res.data.city);
                    localStorage.setItem('ustate', res.data.state);
                    localStorage.setItem('ucountry', res.data.country);
                    localStorage.setItem('utimezone', res.data.timezone);

                    navigate("/chat", { replace: true })
                } else {
                    setError("Email already exist");
                    setIsError(true);
                }
            }
        } catch (error) {
            console.error('Error uploading img : ', error);
            setError(error);
            setIsError(true);
        }
    }
    const hrefhandle = () => {
        navigate("/", { replace: true })
    }

    const handleSubmit = (e) => {
        if (businessName === '') {
            e.preventDefault();
            setError("Enter your businessName");
            setIsError(true);
        } else if (name === '') {
            e.preventDefault();
            setError("Enter your name");
            setIsError(true);
        } else if (mobile === '') {
            e.preventDefault();
            setError("Enter your Mobile Number");
            setIsError(true);
        } else if (!(mobile.match('[0-9]{12}'))) {
            e.preventDefault();
            setError("Invalid Mobile Number");
            setIsError(true);
        } else if (email === '') {
            e.preventDefault();
            setError("Enter your Email");
            setIsError(true);
        } else if (validateEmail(email) === false) {
            e.preventDefault();
            setError("Invalid  emailId");
            setIsError(true);
        } else if (password === '') {
            e.preventDefault();
            setError("Enter your password");
            setIsError(true);
        } else if (cpassword === '') {
            e.preventDefault();
            setError("Enter your confirm password");
            setIsError(true);
        } else if (cpassword !== password) {
            e.preventDefault();
            setError("password and confirm password should be match!!!");
            setIsError(true);
        } else if (!checkbox) {
            e.preventDefault();
            setError("Accept the terms and conditions");
            setIsError(true);
        } else {
            e.preventDefault();
            registration();
        }
    }

    const resize = () => {
        setIsMobileView(window.innerWidth <= 768 ? true : false);
    };

    useEffect(() => {
        window.addEventListener("resize", resize);
        setIsMobileView(window.innerWidth <= 768 ? true : false);
        return () => {
            window.removeEventListener('resize', resize);
        }
    }, []);
    return (

        <div className="chatslogin_main">
            {!IsMobileView &&
                (<div className="chatslogin_left">
                <img className="chatslogin_left_warpper" alt="texts">

                </img>
                    {/*<div className="chatslogin_left_warpper">
                        <div className="chatslogin_left_logo">
                            <div className="chatslogin_left_logo_img"><img src={require('../../../Images/bulkwiselogo.png')} alt="test" /></div>
                            <div className="chatslogin_left_logo_span"> <span>Leading Automation Partner</span></div>
                        </div>
                    </div>*/}
                </div>)}
            <div className="chatslogin_right">
                <div className="chatslogin_right_warpper">
                    <div className="chatslogin_right_login">
                        <form className='home__container' onSubmit={handleSubmit}>
                            {IsMobileView && <img className="chatsregisterlogo" alt="test"></img>}
                            <div className="chatregistertextmessage">Create your Bulkwise Account</div>
                            <div className="chatregisterwelcometext" >Fill in the details below to  start your 14 Day Free trial.</div>
                            <div className="chatregisterwelcometext" >No Credit Card needed.</div>
                            <div>
                                <input type="text"
                                    minLength={5}
                                    name="businessname"
                                    id='businessname'
                                    className='chatslogin_right_login_input'
                                    value={businessName}
                                    placeholder='Enter your Business Name'
                                    onChange={e => setBusinessName(e.target.value)}
                                /></div>
                            <div>
                                <input type="text"
                                    minLength={5}
                                    name="fullname"
                                    id='fullname'
                                    className='chatslogin_right_login_input'
                                    value={name}
                                    placeholder='Enter your Full Name'
                                    onChange={e => setName(e.target.value)}
                                /></div>
                            <div>
                                <input type="text"
                                    minLength={12}
                                    maxLength={12}
                                    name="mobilenumber"
                                    id='mobilenumber'
                                    className='chatslogin_right_login_input'
                                    value={mobile}
                                    placeholder='Enter your Mobile Number'
                                    onChange={e => setMobile(e.target.value)}
                                /></div>
                            <div>
                                <input type="text"
                                    minLength={5}
                                    name="emailid"
                                    id='emailid'
                                    className='chatslogin_right_login_input'
                                    value={email}
                                    placeholder='Enter your Email id'
                                    onChange={e => setEmail(e.target.value)}
                                /></div>
                            <div className="chatslogin_right_login_password_wrapper"><input type={passwordShown ? "text" : "password"}
                                minLength={5}
                                name="password"
                                id='password'
                                className='chatslogin_right_login_password'
                                value={password}
                                placeholder='Enter Password'
                                onChange={e => setPassword(e.target.value)}
                            /><img className="chatslogin_right_login_password_img" alt="" onClick={togglePasswordVisiblity} />
                            </div>
                            <div className="chatslogin_right_login_password_wrapper"><input type={cpasswordShown ? "text" : "password"}
                                minLength={5}
                                name="confirmpassword"
                                id='confirmpassword'
                                className='chatslogin_right_login_password'
                                value={cpassword}
                                placeholder='Confirm Password'
                                onChange={e => setCPassword(e.target.value)}
                            /><img className="chatslogin_right_login_password_img" alt="" onClick={toggleCPasswordVisiblity} />
                            </div>
                            <div className="chatloginregistermessage">
                                <input type="checkbox"
                                    name="checkbox"
                                    id='checkbox'
                                    value={checkbox}
                                    onChange={e => setCheckbox(e.target.value)}
                                />
                                I agree to the
                                <span href="html_images.asp" target="_blank">Terms of Service & Private Policy</span></div>
                            <button className='chatslogin_right_login_button'>Register</button>
                            <div className="chatloginregistermessage">Already Having Account ?
                                <span onClick={hrefhandle} target="_parent"> LOGIN NOW</span></div>
                            <div hidden={!IsError}><span className="chatsregister_right_error">{error}</span></div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Register