import React from "react";
import "./UserInfo.css";
import { useNavigate } from "react-router-dom"
import CountdownTimer from './CountdownTimer';
const bcolor = ['#D4FFC5', '#E8D1FF', '#FFC5CC', '#FEFFC5', '#FFC5F6', '#C5F1FF', '#C5FFEA', '#F0FFC5', '#FFEBC5', '#C5E3FF', '#FFEDC5', '#FFD7C5', '#CDC5FF', '#FFC5D3', '#F7FFC5', '#FFDAC5', '#FFF2C5', '#FFC5E8'];
const tcolor = ['#4BAC29', '#8239CC', '#CC5462', '#BBBE2B', '#C663B7', '#42A3C2', '#5DAD90', '#98BB35', '#E6A323', '#318CE0', '#E5DC18', '#E0581B', '#280BDA', '#DC2652', '#C1DB20', '#EF671A', '#DBDF1E', '#D8238F'];

const UserInfo = ({ imgId, caption }) => {
    const NOW_IN_MS = new Date().getTime();
    const nNOW_IN_MS = new Date(localStorage.getItem("active")).getTime();
    const value = (NOW_IN_MS - nNOW_IN_MS) < 86400000 ? (NOW_IN_MS - nNOW_IN_MS) : 0;
    const dateTimeAfterThreeDays = (value === 0 ? 0 : (NOW_IN_MS + (86400000 - value)));
    const getBcolor = (number) => {
        if (number.length !== 12)
            return bcolor[0];
        else
            return bcolor[parseInt(number[10]) + parseInt(number[11])];
    }
    const getTcolor = (number) => {
        if (number.length !== 12)
            return tcolor[0];
        else
            return tcolor[parseInt(number[10]) + parseInt(number[11])];
    }
    const getName = (name) => {
        return name.split(' ').filter((n, idx) => idx < 2).map((n) => n[0]).join('').toUpperCase()
    }
    const navigate = useNavigate()
    const handleLeaveChat = () => {
        localStorage.removeItem("userName")
        navigate("/")
        window.location.reload()
    }
    return (
        <div>
            <div className="chatbody__temp_header">
                {
                    localStorage.getItem("selectedNumber") !== null ?
                        (<div style={{ display: "flex" }}>
                            <div className="chatbody__temp_header_time"> <CountdownTimer targetDate={dateTimeAfterThreeDays} /> </div>
                            <img src={require('../../../Images/logout.png')} alt="test" style={{ width: '40px', height: '40px', padding: '10px', marginLeft: 'auto', marginRight: '0px' }} onClick={handleLeaveChat} /></div>)
                        : <div></div>
                }
            </div>
            <div className="chatbody__temp_main">
                {
                    localStorage.getItem("selectedNumber") !== null ?
                        (<div >
                            <div >
                                <div className="chatbar_user_avatar_outer_userinfo">
                                    <div style={{ backgroundColor: getBcolor(localStorage.getItem("selectedNumber")) }} className="chatbar_user_avatar_inner_userinfo">  <span style={{ color: getTcolor(localStorage.getItem("selectedNumber")) }} className="avatar">{getName(localStorage.getItem("selectedName"))}</span></div>
                                </div>
                            </div>
                            <div>
                                <div>{localStorage.getItem("selectedName")}</div>
                                <div>{localStorage.getItem("selectedNumber")}</div>
                            </div>
                        </div>)
                        : <div></div>
                }</div>
        </div>
    );
}
export default UserInfo;