import React, { useEffect, useState, useRef } from 'react'
import { useNavigate } from "react-router-dom"
import { format } from 'date-fns';
import ChatBodyR from './ChatBodyR'
import ChatBodyS from './ChatBodyS'
import ChatFooter from './ChatFooter'
import "./ChatBody.css";
import CountdownTimer from './CountdownTimer';
const bcolor = ['#D4FFC5', '#E8D1FF', '#FFC5CC', '#FEFFC5', '#FFC5F6', '#C5F1FF', '#C5FFEA', '#F0FFC5', '#FFEBC5', '#C5E3FF', '#FFEDC5', '#FFD7C5', '#CDC5FF', '#FFC5D3', '#F7FFC5', '#FFDAC5', '#FFF2C5', '#FFC5E8'];
const tcolor = ['#4BAC29', '#8239CC', '#CC5462', '#BBBE2B', '#C663B7', '#42A3C2', '#5DAD90', '#98BB35', '#E6A323', '#318CE0', '#E5DC18', '#E0581B', '#280BDA', '#DC2652', '#C1DB20', '#EF671A', '#DBDF1E', '#D8238F'];
const ChatBody = ({ backhOnmobileviewChat, IsMobileView, lastMessageRef,socket, messageTextRef, handlereplyclose, replymessage, handlereply, timer, unread, onUnReadUpdate, resolved, onResolvedUpdate, blocked, onBlockUpdate, messages, typingStatus }) => {

    const messageRef = useRef(null);

    const NOW_IN_MS = new Date().getTime();
    const nNOW_IN_MS = new Date(localStorage.getItem("active")).getTime();

    const value = (NOW_IN_MS - nNOW_IN_MS) < 86400000 ? (NOW_IN_MS - nNOW_IN_MS) : 0;

    const dateTimeAfterThreeDays = (value === 0 ? 0 : (NOW_IN_MS + (86400000 - value)));

    const getBcolor = (number) => {
        if (number.length !== 12)
            return bcolor[0];
        else
            return bcolor[parseInt(number[10]) + parseInt(number[11])];
    }

    const getTcolor = (number) => {
        if (number.length !== 12)
            return tcolor[0];
        else
            return tcolor[parseInt(number[10]) + parseInt(number[11])];
    }
    const getName = (name) => {
        return name.split(' ').filter((n, idx) => idx < 2).map((n) => n[0]).join('').toUpperCase()
    }

    const navigate = useNavigate()
    const handleLeaveChat = () => {
        localStorage.removeItem("userName")
        navigate("/")
        window.location.reload()
    }

    const handlefocus = (contextid) => {
        document.getElementById(contextid).focus();
    }



    const getMessageByContextId = (contextid) => {
        if (contextid !== null) {
            let message = messages.filter(message => message.messageid === contextid);
            if (message.length > 0) {
                return message[0];// setCmessage(message[0]);
            } else {
                return null;
            }
        } else {
            return null;
        }
    }


    return (
        <>
            <div className="chatbody__header">

                {
                    localStorage.getItem("selectedNumber") !== null ?
                        (<div style={{ display: "flex" }}>
                            {IsMobileView && (<div className="backbuttontochatbar"> <img src={require('../../../Images/back.png')} alt="test" onClick={backhOnmobileviewChat} />
                            </div>)}
                            <div className="chatbar_user_avatar_outer">
                                <div style={{ backgroundColor: getBcolor(localStorage.getItem("selectedNumber")) }} className="chatbar_user_avatar_inner">  <span style={{ color: getTcolor(localStorage.getItem("selectedNumber")) }} className="avatar">{getName(localStorage.getItem("selectedName"))}</span></div>
                            </div>
                            <div>
                                <div>{localStorage.getItem("selectedName")}</div>
                                <div>({localStorage.getItem("selectedNumber")})</div>
                            </div>
                            {/*{unread === 1 ? <div><button className="tab_button" onClick={onUnReadUpdate}>un-unread</button></div> : <div><button className="tab_button" onClick={onUnReadUpdate}>read</button></div>}
                            {resolved === 1 ? <div><button className="tab_button" onClick={onResolvedUpdate}>un-resolved</button></div> : <div ><button className="tab_button" onClick={onResolvedUpdate}>resolved</button></div>}
                            {blocked === 1 ? <div><button className="tab_button" onClick={onBlockUpdate}>un-blocked</button></div> : <div><button className="tab_button" onClick={onBlockUpdate}>blocked</button></div>}
                          */}  {/*<CountdownTimer targetDate={dateTimeAfterThreeDays} />*/}
                        </div>)
                        : <div className="chatbodyconverstion" ><p> Conversations</p></div>
                }
                {/* <button className='leaveChat__btn' >Sign out</button>*/}
                
            </div>
            {messages.length > 0 ? (
                <div className='chatbody__main' ref={messageRef}>
                    {
                        messages.map(message => (

                            message.source === 'client'
                                ?
                                (
                                    <div key={message.id} id={message.messageid} tabIndex={-1}>
                                        <ChatBodyS handlereply={handlereply} handlefocus={handlefocus} message={message} context={getMessageByContextId(message.contextid)} />
                                    </div>
                                ) : (
                                    <div key={message.id} id={message.messageid} tabIndex={-1}>
                                        <ChatBodyR handlereply={handlereply} handlefocus={handlefocus} message={message} context={getMessageByContextId(message.contextid)} />
                                    </div>
                                )
                        ))
                    }

                    {/* <div className='message__status'>
                        <p>{typingStatus}</p>
                    </div>*/}
                    <div ref={lastMessageRef} />
                </div>
            ) : <div className='chatbody__main_empty' />}
            <div className="chatbody__footer">
                <ChatFooter socket={socket} messageTextRef={messageTextRef} handlereplyclose={handlereplyclose}  replymessage={replymessage } />
            </div>
        </>
    )
}

export default ChatBody