import React, { useState } from 'react'
import AsyncUploadImage from './AsyncUploadImage'
import AsyncUploadVideo from './AsyncUploadVideo'
import AsyncUploadDocument from './AsyncUploadDocument'
import AsyncUploadAudio from './AsyncUploadAudio'
import PlayVideo from './PlayVideo'
import PlayAudio from './PlayAudio'
import AsyncDocument from './AsyncDocument'
import "./ChatFooter.css";
const ChatFooter = ({ socket, messageTextRef, handlereplyclose, replymessage }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenImage, setIsOpenImage] = useState(false);
    const [isOpenVideo, setIsOpenVideo] = useState(false);
    const [isOpenDocument, setIsOpenDocument] = useState(false);
    const [isOpenAudio, setIsOpenAudio] = useState(false);

    const serverurl = 'https://wabadev.bulkwise.in/images/';
    const handleTyping = (e) => {
        if ((e.keyCode === 13 && e.ctrlKey) || (e.keyCode === 13 && e.altKey)) {
            e.target.value = e.target.value + "\n";

        } else if (e.keyCode === 13) {
            handleSendMessage(e);
        }
    }
    const handleSendMessage = (e) => {
        e.preventDefault()


        socket.emit("message",
            {
                to: localStorage.getItem("selectedNumber"),
                message: messageTextRef.current.value,
                contextid: replymessage.messageid !== undefined ? replymessage.messageid : null
            }
        )
        handlereplyclose();

        messageTextRef.current.value = "";
    }


    const toggleMenu = () => {
        setIsOpen(!isOpen);

    };

    const togglePopupImage = () => {
        setIsOpen(!isOpen);
        setIsOpenImage(!isOpenImage);
    };

    const togglePopupImagepass = () => {
        setIsOpenImage(!isOpenImage);
    };

    const togglePopupAudio = () => {
        setIsOpen(!isOpen);
        setIsOpenAudio(!isOpenAudio);
    };

    const togglePopupAudiopass = () => {
        setIsOpenAudio(!isOpenAudio);
    };

    const togglePopupVideo = () => {
        setIsOpen(!isOpen);
        setIsOpenVideo(!isOpenVideo);
    };

    const togglePopupVideopass = () => {
        setIsOpenVideo(!isOpenVideo);
    };

    const togglePopupDocument = () => {
        setIsOpen(!isOpen);
        setIsOpenDocument(!isOpenDocument);
    };

    const togglePopupDocumentpass = () => {
        setIsOpenDocument(!isOpenDocument);
    };

    const formatText = (text) => {
        var val = text.replace(/\*(.*?)\*/g, '<b>$1</b>');
        return val.replace(/(?:\r\n|\r|\n)/g, "<br>");
    }

    return (
        <div className='chatbarfooder_item'>
           
            <div className='chatbarfooder_plus'>
                <button className='chatbarfooder_plus_button' onClick={toggleMenu}>{isOpen ? '-' : '+'} </button>
                {isOpen && (
                    <div class="dropup-content">
                        <div style={{ display: "flex", textAlign: "center" }} onClick={togglePopupImage}>
                            <img src={require('../../../Images/chat-image.png')} alt="test" style={{ width: '40px', height: '40px', padding: '5px' }} />
                            <span style={{ marginTop: "10px" }}>image</span> </div>
                        <div style={{ display: "flex" }} onClick={togglePopupAudio}>
                            <img src={require('../../../Images/chat-audio.png')} alt="test" style={{ width: '40px', height: '40px', padding: '5px' }} />
                            <span style={{ marginTop: "10px" }}>Audio</span></div>
                        <div style={{ display: "flex" }} onClick={togglePopupVideo}>
                            <img src={require('../../../Images/chat-video.png')} alt="test" style={{ width: '40px', height: '40px', padding: '5px' }} />
                            <span style={{ marginTop: "10px" }}>Video</span></div>
                        <div style={{ display: "flex" }} onClick={togglePopupDocument}>
                            <img src={require('../../../Images/chat-doc.png')} alt="test" style={{ width: '40px', height: '40px', padding: '5px' }} />
                            <span style={{ marginTop: "10px" }}>Document</span></div>
                    </div>
                )}
            </div>

            <div className='chatbarfooder_text' onSubmit={handleSendMessage}>
                <textarea
                    id="chatbarfooder_textareaid"
                    type="text"
                    placeholder='Write message'
                    className='chatbarfooder_textarea'
                    ref={messageTextRef}
                    onKeyDown={handleTyping}
                />
                {replymessage.messageid !== undefined && (
                    <div>
                        <div class="sendmessageouter-reply">
                            {replymessage.type === 'text' ? <p style={{ maxHeight: '80px', maxWidth: '80px' }} dangerouslySetInnerHTML={{ __html: formatText(replymessage.message) }} /> :
                                (replymessage.type === 'image' ? <img style={{ maxHeight: '80px', maxWidth: '80px' }} src={serverurl + replymessage.message} alt="icons" /> :
                                    (replymessage.type === 'video' ? <PlayVideo style={{ maxHeight: '80px', maxWidth: '80px' }} sourceId={replymessage.message} caption={replymessage.caption}> </PlayVideo> :
                                        (replymessage.type === 'audio' ? <PlayAudio style={{ maxHeight: '80px', maxWidth: '80px' }} sourceId={replymessage.message} caption={replymessage.caption}> </PlayAudio> :
                                            (replymessage.type === 'document' ? <AsyncDocument style={{ maxHeight: '80px', maxWidth: '80px' }} sourceId={replymessage.message} caption={replymessage.caption}> </AsyncDocument> : < p > {replymessage.message}</p>)))
                                )}
                        </div>
                        <div class="cancelreplymessage">
                            <span className="remove_img" onClick={() => handlereplyclose()}  >
                                X
                            </span>
                        </div>
                    </div>
                )}
            </div>

            {/*<div className="sendmessagebutton"><img src={require('../../../Images/templates.png')} alt="test" style={{ width: '40px', height: '40px', padding: '5px' }}  /></div>*/}
            <div className="chatbarfooder_send">
                <button className='chatbarfooder_sendbutton' onClick={handleSendMessage}>Send Message</button>
            </div>


            {isOpenImage && (
                <div className="playpopup-container">
                    <div className="playpopup">
                        <AsyncUploadImage socket={socket} togglePopupImage={togglePopupImagepass} />
                    </div>
                </div>
            )}
            {isOpenAudio && (
                <div className="playpopup-container">
                    <div className="playpopup">
                        <AsyncUploadAudio socket={socket} togglePopupAudio={togglePopupAudiopass} />
                    </div>
                </div>
            )}
            {isOpenVideo && (
                <div className="playpopup-container">
                    <div className="playpopup">
                        <AsyncUploadVideo socket={socket} togglePopupVideo={togglePopupVideopass} />
                    </div>
                </div>
            )}
            {isOpenDocument && (
                <div className="playpopup-container">
                    <div className="playpopup">
                        <AsyncUploadDocument socket={socket} togglePopupDocument={togglePopupDocumentpass} />
                    </div>
                </div>
            )}

        </div>
    )
}

export default ChatFooter