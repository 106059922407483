import AsyncAudio from './AsyncAudio'
const PlayAudioPopup = ({ sourceId, toggleAudioPlay }) => {
	return (
		<div>
			<h1 style={{ textAlign: "center" }}>Play Audio</h1>
			<AsyncAudio sourceId={sourceId}> </AsyncAudio>
			<div ><button onClick={toggleAudioPlay}>close</button></div>
		</div>
	);
}
export default PlayAudioPopup