import "./ChatBarFilter.css";
const ChatBarFilter = ({ onPageUpdate, selectedchatbarmenu, chatbarfiltermenuItems }) => {
    return (
        <div className="chatbarfilter_main">
            {chatbarfiltermenuItems.map((chatbarfiltermenuItem, index) =>
                <button onClick={() => onPageUpdate(chatbarfiltermenuItem)}
                    className={selectedchatbarmenu === chatbarfiltermenuItem ?
                        "chatbarfilter_button_selected" : "chatbarfilter_button"}
                    key={index}>{chatbarfiltermenuItem}</button>
            )}
        </div>
    )
}

export default ChatBarFilter