// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidemenuItems {
    height: 100%;
    width: 100%;
    background-color: #2CB53A;
    padding:0px;
}

    .sidemenuItems > * {
        padding: 10px;
    }
`, "",{"version":3,"sources":["webpack://./src/pages/Components/Chats/SideMenu.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,WAAW;AACf;;IAEI;QACI,aAAa;IACjB","sourcesContent":[".sidemenuItems {\r\n    height: 100%;\r\n    width: 100%;\r\n    background-color: #2CB53A;\r\n    padding:0px;\r\n}\r\n\r\n    .sidemenuItems > * {\r\n        padding: 10px;\r\n    }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
