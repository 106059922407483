// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatbody__temp_header {
    width: 100%;
    height: 10vh;
    margin: 0px;
    background-color: #2CB53A;
}

.chatbody__temp_header_time {
    width: 80%;
    height: 100%;
    margin: 0px;
    text-align: center;
    justify-content: center;
}

.chatbody__temp_main {
    width: 100%;
    height: 80vh;
    margin: 0px;
    background-color: white;
    text-align: center;
    justify-content: center;
}


.chatbar_user_avatar_outer_userinfo {
    flex: 0.15 1;
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    width: 100%;
}

.chatbar_user_avatar_inner_userinfo {
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Components/Chats/UserInfo.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,kBAAkB;IAClB,uBAAuB;AAC3B;;;AAGA;IACI,YAAU;IACV,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;IAClB,aAAa;IACb,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;AACvB","sourcesContent":[".chatbody__temp_header {\r\n    width: 100%;\r\n    height: 10vh;\r\n    margin: 0px;\r\n    background-color: #2CB53A;\r\n}\r\n\r\n.chatbody__temp_header_time {\r\n    width: 80%;\r\n    height: 100%;\r\n    margin: 0px;\r\n    text-align: center;\r\n    justify-content: center;\r\n}\r\n\r\n.chatbody__temp_main {\r\n    width: 100%;\r\n    height: 80vh;\r\n    margin: 0px;\r\n    background-color: white;\r\n    text-align: center;\r\n    justify-content: center;\r\n}\r\n\r\n\r\n.chatbar_user_avatar_outer_userinfo {\r\n    flex: 0.15;\r\n    max-width: fit-content;\r\n    margin-left: auto;\r\n    margin-right: auto;\r\n    padding: 10px;\r\n    width: 100%;\r\n}\r\n\r\n.chatbar_user_avatar_inner_userinfo {\r\n    height: 50px;\r\n    width: 50px;\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    border-radius: 50px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
