// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.datetimedisplay {
    height: 20px;
    width: 20px;
    padding: 2px;
    background-color: white;
    text-align: center;
    font-size: 16px;
}


.datetimedisplay > p  {
   margin:0px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Components/Chats/DateTimeDisplay.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,eAAe;AACnB;;;AAGA;GACG,UAAU;AACb","sourcesContent":[".datetimedisplay {\r\n    height: 20px;\r\n    width: 20px;\r\n    padding: 2px;\r\n    background-color: white;\r\n    text-align: center;\r\n    font-size: 16px;\r\n}\r\n\r\n\r\n.datetimedisplay > p  {\r\n   margin:0px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
