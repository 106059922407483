import React from 'react'
import "./SearchBar.css";
const SeacrchBar = ({ onSearchBar, searchTextRef }) => {
    return (
        <div className='seacrch_bar_user_main'>
            <form  >
                <input className='seacrch_bar_user'
                    type="text"
                    ref={searchTextRef}
                    placeholder='Search by Name or Number'
                    onChange={onSearchBar}
                />
            </form>
        </div>
    )
}
export default SeacrchBar
