// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.chatbarfilter_main {
    width: 100%;
    height: 5vh;
    padding:1vh;
    background-color:white;
    display:flex;
}
.chatbarfilter_button {
    background-color: #D9D9D9;
    border-radius: 40px;
    float: left;
    border: 1px solid #000;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    margin: 3px;
    color: #54656F;
}
.chatbarfilter_button_selected {
    background-color: #E7FCE3;
    border-radius: 40px;
    float: left;
    border: 1px solid #000;
    padding-left: 6px;
    padding-right: 6px;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 12px;
    margin: 3px;
    color: #008069;
}`, "",{"version":3,"sources":["webpack://./src/pages/Components/Chats/ChatBarFilter.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,WAAW;IACX,WAAW;IACX,sBAAsB;IACtB,YAAY;AAChB;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,cAAc;AAClB;AACA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,WAAW;IACX,sBAAsB;IACtB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;IACf,WAAW;IACX,cAAc;AAClB","sourcesContent":[".chatbarfilter_main {\r\n    width: 100%;\r\n    height: 5vh;\r\n    padding:1vh;\r\n    background-color:white;\r\n    display:flex;\r\n}\r\n.chatbarfilter_button {\r\n    background-color: #D9D9D9;\r\n    border-radius: 40px;\r\n    float: left;\r\n    border: 1px solid #000;\r\n    padding-left: 6px;\r\n    padding-right: 6px;\r\n    padding-top: 3px;\r\n    padding-bottom: 3px;\r\n    font-size: 12px;\r\n    margin: 3px;\r\n    color: #54656F;\r\n}\r\n.chatbarfilter_button_selected {\r\n    background-color: #E7FCE3;\r\n    border-radius: 40px;\r\n    float: left;\r\n    border: 1px solid #000;\r\n    padding-left: 6px;\r\n    padding-right: 6px;\r\n    padding-top: 3px;\r\n    padding-bottom: 3px;\r\n    font-size: 12px;\r\n    margin: 3px;\r\n    color: #008069;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
