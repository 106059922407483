import React, { useState } from 'react'
import PlayVideoPopup from './PlayVideoPopup'
const PlayVideo = ({ sourceId, caption }) => {

    const [isVideoPlay, setIsVideoPlay] = useState(false);
    const toggleVideoPlay = () => {
        setIsVideoPlay(!isVideoPlay);
    };

    return (
        <div className="playvideo">
            <img src={require('../../../Images/chat-sending-video-image.png')} alt="test" style={{ width: '128px', height: '72px' }} onClick={toggleVideoPlay} />
            {isVideoPlay && (
                <div className="playpopup-container">
                    <div className="playpopup">
                        <PlayVideoPopup sourceId={sourceId} toggleVideoPlay={toggleVideoPlay} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default PlayVideo