import React, { useState, useEffect } from 'react'
import SearchBar from './SearchBar.js'
import ChatBarFilter from './ChatBarFilter.js'
import "./ChatBar.css";
import { format } from 'date-fns';
const bcolor = ['#D4FFC5', '#E8D1FF', '#FFC5CC', '#FEFFC5', '#FFC5F6', '#C5F1FF', '#C5FFEA', '#F0FFC5', '#FFEBC5', '#C5E3FF', '#FFEDC5', '#FFD7C5', '#CDC5FF', '#FFC5D3', '#F7FFC5', '#FFDAC5', '#FFF2C5', '#FFC5E8'];
const tcolor = ['#4BAC29', '#8239CC', '#CC5462', '#BBBE2B', '#C663B7', '#42A3C2', '#5DAD90', '#98BB35', '#E6A323', '#318CE0', '#E5DC18', '#E0581B', '#280BDA', '#DC2652', '#C1DB20', '#EF671A', '#DBDF1E', '#D8238F'];
const ChatBar = ({ onPageUpdate, selectedchatbarmenu, chatbarfiltermenuItems, selectedNumber, onCharBarUpdate, roots, socket, onSearchBar, searchTextRef }) => {
    const [users, setUsers] = useState([])



    const getBcolor = (number) => {
        if (number.length !== 12)
            return bcolor[0];
        else
            return bcolor[parseInt(number[10]) + parseInt(number[11])];
    }

    const getTcolor = (number) => {
        if (number.length !== 12)
            return tcolor[0];
        else
            return tcolor[parseInt(number[10]) + parseInt(number[11])];
    }


    const formatText = (text) => {
        return text.replace(/\*(.*?)\*/g, '<b>$1</b>');
    }
    const formatDate = (date) => {
        var today = new Date(),
            input = new Date(date),
            diff_days = today.getDate() - input.getDate(),
            diff_month = today.getMonth() - input.getMonth(),
            diff_year = today.getFullYear() - input.getFullYear();
        if (diff_days === 0 && diff_month === 0 && diff_year === 0) {
            return format(new Date(date), "hh:mm a")
        } else {
            return format(new Date(date), "dd/MM/yyyy")
        }
    };
    const getName = (name) => {
        return name.split(' ').filter((n, idx) => idx < 2).map((n) => n[0]).join('').toUpperCase()
    }


    return (
        <>
            <div className='chatbar_header'>
                <div className="chatbarheadername"><p>Chats</p></div>
                <SearchBar onSearchBar={onSearchBar} searchTextRef={searchTextRef} />
                <ChatBarFilter onPageUpdate={onPageUpdate} selectedchatbarmenu={selectedchatbarmenu} chatbarfiltermenuItems={chatbarfiltermenuItems} />

            </div>
               
           
             <div className="chatbar_main">
            {roots.map(root => root.visible ? (
                <div onClick={() => onCharBarUpdate(root.number)} className="chatbar_users_item">
                    <div className="chatbar_user_avatar_outer">
                        <div style={{ backgroundColor: getBcolor(root.number) }} className="chatbar_user_avatar_inner">  <span style={{ color: getTcolor(root.number) }} className="avatar">{getName(root.name)}</span></div>
                    </div>
                    <div className="chatbar_users_item_content">
                        <div className="chatbar_users_item_inner">
                            <p className="chatbar_users_item_name">{root.name}</p>
                            <p className="chatbar_users_item_time">{root.converstation[root.converstation.length - 1].createdat !== undefined ? formatDate(root.converstation[root.converstation.length - 1].createdat) : ''}</p>
                        </div>
                        <div className="chatbar_users_item_inner">
                            <div className="chatbar_users_item_tick">
                                {root.converstation[root.converstation.length - 1].status === null ? <div />
                                    : (root.converstation[root.converstation.length - 1].status === 'read' ? <div className="chatbar_users_item_message_image_div"> <img className="chatbar_users_item_message_image" alt="" src={require('../../../Images/chatlist-bluetick.png')} /></div>
                                        : (root.converstation[root.converstation.length - 1].status === 'sent' || root.converstation[root.converstation.length - 1].status === 'init')
                                            ? <div className="chatbar_users_item_message_image_div"> <img className="chatbar_users_item_message_image" alt="" src={require('../../../Images/chatlist-singletick.png')} /> </div>
                                            : <div className="chatbar_users_item_message_image_div"><img className="chatbar_users_item_message_image" alt="" src={require('../../../Images/chatlist-doubletick.png')} /></div>
                                   )
                                }
                            </div>
                            <div className="chatbar_users_item_message">
                                {root.converstation[root.converstation.length - 1].type === 'text'
                                    ? <p className='chat_users_item_message' dangerouslySetInnerHTML={{ __html: formatText(root.converstation[root.converstation.length - 1].message) }} />
                                    : (root.converstation[root.converstation.length - 1].type === 'video' ? <div style={{ display: 'flex' }}><img src={require('../../../Images/chatlist-video.png')} alt="video" style={{ width: '20px', height: '20px', marginLeft: '20px', marginRight: '5px' }} /> <span style={{ marginRight: '120px' }}>Video</span> </div>
                                        : (root.converstation[root.converstation.length - 1].type === 'audio' ? <div style={{ display: 'flex' }}><img src={require('../../../Images/chatlist-audio.png')} alt="audio" style={{ width: '20px', height: '20px', marginLeft: '20px', marginRight: '5px' }} /> <span style={{ marginRight: '120px' }}>Audio</span> </div>
                                            : (root.converstation[root.converstation.length - 1].type === 'document' ? <div style={{ display: 'flex' }}><img src={require('../../../Images/chatlist-doc.png')} alt="document" style={{ width: '20px', height: '20px', marginLeft: '20px', marginRight: '5px' }} /> <span style={{ marginRight: '90px' }}>Document</span> </div>
                                                : (root.converstation[root.converstation.length - 1].type === 'image' ? <div style={{ display: 'flex' }}><img src={require('../../../Images/chatlist-image.png')} alt="images" style={{ width: '20px', height: '20px', marginLeft: '20px', marginRight: '5px' }} /> <span style={{ marginRight: '120px' }}>Photo</span> </div>
                                                    : <div className='chat_users_item_message' dangerouslySetInnerHTML={{ __html: formatText(root.converstation[root.converstation.length - 1].message) }} />
                                                ))))
                                }
                            </div>
                            <div className="chatbar_users_item_unread">
                                {root.unread > 0 ? <div className='chatbar_users_item_unread_inner'> <span>{root.unread}</span></div> : <div></div>}
                            </div>
                        </div>
                    </div>
                </div >)
                : <div></div>)}
            </div>
        </>
    )
}
export default ChatBar