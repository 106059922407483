import React, { useEffect, useState, useRef } from 'react'
import AsyncImage from './AsyncImage'
import PlayVideo from './PlayVideo'
import PlayAudio from './PlayAudio'
import AsyncDocument from './AsyncDocument'
import { format } from 'date-fns';
import "./ChatBodyR.css";

const ChatBodyR = ({ handlereply, handlefocus, message, context }) => {

    const formatText = (text) => {
        var val = text.replace(/\*(.*?)\*/g, '<b>$1</b>');
        return val.replace(/(?:\r\n|\r|\n)/g, "<br>");
    }



    const formatDate = (date) => {
        var today = new Date(),
            input = new Date(date),
            diff_days = today.getDate() - input.getDate(),
            diff_month = today.getMonth() - input.getMonth(),
            diff_year = today.getFullYear() - input.getFullYear();

        if (diff_days === 0 && diff_month === 0 && diff_year === 0) {
            return format(new Date(date), "hh:mm a")
        } else {
            return format(new Date(date), "dd/MM/yyyy hh:mm a")
        }
    };


    return (
        <div style={{ display:"flex", alignItems: "center" }}>
            <div>
            {context !== null
                    ? <div> <div className="message__recipient_main">
                    <div className="message__recipient_reply_inner">
                        <div className="message__recipient_reply_inner_round">
                            {context.source === 'client' ? <span className="message__recipient_reply_inner_span">you</span> : <span className="message__recipient_reply_inner_span">whatsapp</span>}
                            {context.type === 'text' ? <p className="message__recipient_reply_inner_p" dangerouslySetInnerHTML={{ __html: formatText(context.message) }} onClick={() => handlefocus(message.contextid)} />
                                : (context.type === 'image' ? <img src={require('../../../Images/chatlist-image.png')} alt="images" style={{ width: '20px', height: '20px', marginLeft: '20px', marginRight: '5px' }} onClick={() => handlefocus(message.contextid)} /> :
                                    (context.type === 'video' ? <img src={require('../../../Images/chatlist-video.png')} alt="video" style={{ width: '20px', height: '20px', marginLeft: '20px', marginRight: '5px' }} onClick={() => handlefocus(message.contextid)} /> :
                                        (context.type === 'audio' ? <img src={require('../../../Images/chatlist-audio.png')} alt="audio" style={{ width: '20px', height: '20px', marginLeft: '20px', marginRight: '5px' }} onClick={() => handlefocus(message.contextid)} /> :
                                            (context.type === 'document' ? <img src={require('../../../Images/chatlist-doc.png')} alt="document" style={{ width: '20px', height: '20px', marginLeft: '20px', marginRight: '5px' }} onClick={() => handlefocus(message.contextid)} />
                                                : < p onClick={() => handlefocus(message.contextid)}> {context.message}</p>)))
                                    )}
                                {message.type === 'text' ? <p className="message__recipient_reply_inner_message" dangerouslySetInnerHTML={{ __html: formatText(message.message) }} /> : (message.type === 'image' ? <AsyncImage imgId={message.message} caption={message.caption}> </AsyncImage> :
                                (message.type === 'video' ? <PlayVideo sourceId={message.message} caption={message.caption}> </PlayVideo> :
                                    (message.type === 'audio' ? <PlayAudio sourceId={message.message} caption={message.caption}> </PlayAudio> :
                                        (message.type === 'document' ? <AsyncDocument sourceId={message.message} caption={message.caption}> </AsyncDocument> : < p > {message.message}</p>)))
                            )}

                        </div>

                        <div className='message__recipientdetails'>
                            <p>{message.createdat !== undefined ? formatDate(message.createdat) : ''}</p>
                        </div>
                    </div>
                </div>
                    </div>
                    : <div className="message__recipient_main">
                        {message.type === 'text' ? <div className="message__recipient_inner"> <p className="message__recipient_main_p" dangerouslySetInnerHTML={{ __html: formatText(message.message) }} /> </div> : (message.type === 'image' ? <AsyncImage imgId={message.message} caption={message.caption}> </AsyncImage> :
                                (message.type === 'video' ? <PlayVideo sourceId={message.message} caption={message.caption}> </PlayVideo> :
                                    (message.type === 'audio' ? <PlayAudio sourceId={message.message} caption={message.caption}> </PlayAudio> :
                                        (message.type === 'document' ? <AsyncDocument sourceId={message.message} caption={message.caption}> </AsyncDocument> : < p > {message.message}</p>)))
                            )}
                            <div className='message__recipientdetails'>
                                <p>{message.createdat !== undefined ? formatDate(message.createdat) : ''}</p>
                            </div>
                       
                    </div>}

                </div>
            {/*<button onClick={() => handlereply(message.messageid)}>reply</button>*/}
            <img src={require('../../../Images/reply-right.png')} alt="test" style={{ width: '30px', height: '30px', padding: '5px' }} onClick={() => handlereply(message.messageid)} />
        </div>
    )
}
export default ChatBodyR;