import React, { useEffect, useState, useRef } from "react";
import MenuItem from "../../MenuItem";
import { MdMenu } from "react-icons/md";
import "../..//menu.css";
import "./Chats.css";
import ChatBar from "./ChatBar";
import ChatBody from "./ChatBody";
import UserInfo from "./UserInfo";
import SideMenu from "./SideMenu"
import axios from 'axios'

function Chats({ socket }) {

    const messageTextRef = useRef(null);
    const lastMessageRef = useRef(null);
    const [replymessage, setReplymessage] = useState({});
    const [selectedchatbarmenu, setSelectedchatbarmenu] = useState("")
    const [IsMobileView, setIsMobileView] = useState(true);
    const [mobileviewChatMessage, setMobileViewChatMessage] = useState(false);
    const [userinfo, setUserinfo] = useState(true);

    const menu = [
        {
            icon: MdMenu,
            text: "New",
            link: "/new"
        },
        {
            icon: MdMenu,
            text: "Home",
            link: "/Home"
        },
        {
            icon: MdMenu,
            text: "Chats",
            link: "/Chats"
        },
    ]

    const chatbarfiltermenuItems = ["All", "Unread", "resolved", "blocked"];


    const [isHidden, setIsHidden] = useState(true);
    const [backhOnmobileviewChatStyle, setBackhOnmobileviewChatStyle] = useState({
        zIndex: 0,
        backgroundColor: "rgba(0,0,0,0)",
    });
    const [whiteSpaceTargetStyle, setWhiteSpaceTargetStyle] = useState({
        zIndex: 0,
        backgroundColor: "rgba(0,0,0,0)",
    });

    const resize = () => {
        setIsHidden(window.innerWidth <= 768 ? true : false);
        setIsMobileView(window.innerWidth <= 768 ? true : false);

        console.log(window.innerWidth, "===", isHidden, "===", window.innerHeight);
    };

    const toggleMenu = () => {
        if (isHidden) {
            showMenu();
        } else {
            hideMenu();
        }
    };

    const showMenu = () => {
        if (isHidden) {
            setIsHidden(false);
            setWhiteSpaceTargetStyle({
                zIndex: 990,
                backgroundColor: "rgba(100,100,100,0.3)",
            });
            setBackhOnmobileviewChatStyle({
                zIndex: 990,
                backgroundColor: "rgba(100,100,100,0.3)",
            });
        }
    };

    const hideMenu = () => {
        if (!isHidden) {
            setIsHidden(true);
            setWhiteSpaceTargetStyle({
                zIndex: 0,
                backgroundColor: "rgba(0,0,0,0)",
            });
            setBackhOnmobileviewChatStyle({
                zIndex: 100,
                backgroundColor: "rgba(0,0,0,0)",
            });
        }
    };

    const classList = () => {
        if (isHidden) {
            return "menu hidden";
        }
        return "menu";
    };

    useEffect(() => {
        window.addEventListener("resize", resize);
        setIsHidden(window.innerWidth <= 768);
        setIsMobileView(window.innerWidth <= 768);
        setWhiteSpaceTargetStyle({
            zIndex: isHidden ? 0 : 990,
            backgroundColor: isHidden
                ? "rgba(0,0,0,0)"
                : "rgba(100,100,100,0.3)",
        });
        setBackhOnmobileviewChatStyle({
            zIndex: isHidden ? 200 : 990,
            backgroundColor: isHidden
                ? "rgba(0,0,0,0)"
                : "rgba(200,100,100,0.3)",
        });
        return () => {
            window.removeEventListener('resize', resize);
        }
    }, []);
    const [roots, setRoots] = useState([])
    const [messages, setMessages] = useState([])
    const getData = () => {
        axios.get('https://wabadev.bulkwise.in/api/converstaion')
            .then((res) => {
                console.log("RESPONSE RECEIVED:=== ", res.data);
                setRoots(res.data);

            })
            .catch((err) => {
                console.log("AXIOS ERROR: ", err);
            })
    }

    useEffect(() => {
        localStorage.removeItem("selectedNumber");
        localStorage.removeItem("selectedName");
        setSelectedchatbarmenu(chatbarfiltermenuItems[0]);
        setMobileViewChatMessage(false);
        getData();
    }, []);

    const [selectedNumber, setSelectedNumber] = useState("")
    useEffect(() => {
        const eventListener = (data) => {
            const exist = roots.filter(f => f.number === data.number)
            if (exist.length === 0) {
                const item = {
                    number: data.number,
                    name: data.name,
                    visible: data.visible,
                    unread: data.unread,
                    resolved: data.resolved,
                    blocked: data.blocked,
                    active: data.active,
                    converstation: [{
                        id: data.converstation.id,
                        messageid: data.converstation.messageid,
                        message: data.converstation.message,
                        contextfrom: data.converstation.contextfrom,
                        contextid: data.converstation.contextid,
                        type: data.converstation.type,
                        source: data.converstation.source,
                        status: data.converstation.status,
                        caption: data.converstation.caption,
                        createdat: data.converstation.createdat,
                        updatedat: data.converstation.updatedat
                    }]
                };
                setRoots([item, ...roots]);
            } else {
                var count = 0;
                var index = 0;
                var value;
                const updatedRootMessages = roots.map(root => {
                    count = count + 1;
                    if (root.number === data.number) {
                        root.visible = data.visible;
                        root.unread = data.unread;
                        root.resolved = data.resolved;
                        root.blocked = data.blocked;
                        root.active = data.active;
                        index = count;
                        const converstation = {
                            id: data.converstation.id,
                            messageid: data.converstation.messageid,
                            message: data.converstation.message,
                            contextfrom: data.converstation.contextfrom,
                            contextid: data.converstation.contextid,
                            type: data.converstation.type,
                            source: data.converstation.source,
                            status: data.converstation.status,
                            caption: data.converstation.caption,
                            createdat: data.converstation.createdat,
                            updatedat: data.converstation.updatedat
                        };
                        root.converstation.push(converstation);
                        if (selectedNumber === data.number) {
                            setMessages(messages => [...messages, converstation]);
                        }
                        value = root;
                        return root;
                    }
                    return root;
                });
                if (index > 1) {
                    updatedRootMessages.splice((index - 1), 1)
                    updatedRootMessages.unshift(value);
                }
                setRoots(updatedRootMessages);
            }

        };
        socket.on("message", eventListener);
        return () => socket.off("message", eventListener)
    }, [socket, roots, messages, selectedNumber])

    useEffect(() => {
        const eventListener = (data) => {
            const updatedRootMessages = roots.map(root => {
                if (root.number === data.number) {
                    const updatedConverstations = root.converstation.map(converstation => {
                        if (converstation.id === data.converstation.id) {
                            converstation.status = data.converstation.status;
                            converstation.createdat = data.converstation.createdat;
                            return converstation;
                        }
                        return converstation;
                    });
                    root.converstation = updatedConverstations;
                    return root;
                }
                return root;
            });
            setRoots(updatedRootMessages)
        };
        socket.on("update", eventListener);
        return () => socket.off("update", eventListener)
    }, [socket, roots])

    const onCharBarUpdate = (event) => {
        //setIsChatOpen(true);
        setMobileViewChatMessage(true);
        setSelectedNumber(event);
        messageTextRef.current.value = "";
        localStorage.setItem("selectedNumber", event);
        socket.emit("readupdate",
            {
                number: event,
                value: 0
            }
        )
        var result = [];
        var timevalue = "";

        const updatedRootMessages = roots.map(root => {
            if (root.number === event) {
                //setSelectedName(root.name);
                localStorage.setItem("selectedName", root.name);
                localStorage.setItem("unread", root.unread);
                localStorage.setItem("resolved", root.resolved);
                //setUnread(root.unread);
                //setResolved(root.resolved);
                //setBlocked(root.blocked);
                timevalue = Object.assign("", root.active);
                localStorage.setItem("active", root.active);
                result = Object.assign([], root.converstation);
                root.unread = 0;
            }
            return root;
        });
        setRoots(updatedRootMessages);
        setMessages(result);
        //setTimer(timevalue);


        /* roots.forEach((root) => {
             if (root.number === event) {
                 setSelectedName(root.name);
                 localStorage.setItem("selectedName", root.name);
                 result = Object.assign([], root.converstation);
             }
         });*/

    };

    const onSearchBar = (event) => {
        event.preventDefault();
        const updatedRootMessages = roots.map(root => {
            if (!((root.number.includes(event.target.value)
                || root.name.toString().toLowerCase().includes(event.target.value.toString().toLowerCase())))) {
                root.visible = false;
            } else {
                root.visible = true;
            }
            return root;
        });

        setRoots(updatedRootMessages);
    };

    const handlereply = (messageid) => {
        if (messageid !== null) {
            let message = messages.filter(message => message.messageid === messageid);
            if (message.length > 0) {
                setReplymessage(message[0]);
            }
        }
    }

    const handlereplyclose = () => {
        setReplymessage({});
    }

    const onPageUpdate = (event) => {
        setSelectedchatbarmenu(event)
        //searchTextRef.current.value = "";
        messageTextRef.current.value = "";
        if (event === 'Unread') {
            const updatedRootMessages = roots.map(root => {
                if (root.unread > 0) {
                    root.visible = true;
                } else {
                    root.visible = false;
                }
                return root;
            });
            setRoots(updatedRootMessages);
        } else if (event === 'resolved') {
            const updatedRootMessages = roots.map(root => {
                if (root.resolved > 0) {
                    root.visible = true;
                } else {
                    root.visible = false;
                }
                return root;
            });
            setRoots(updatedRootMessages);
        } else if (event === 'blocked') {
            const updatedRootMessages = roots.map(root => {
                if (root.blocked > 0) {
                    root.visible = true;
                } else {
                    root.visible = false;
                }
                return root;
            });
            setRoots(updatedRootMessages);
        } else if (event === 'All') {
            const updatedRootMessages = roots.map(root => {
                if (root.blocked || root.resolved > 0) {
                    root.visible = false;
                } else {
                    root.visible = true;
                }
                return root;
            });
            setRoots(updatedRootMessages);
        }
        //setMessages([]);
        //localStorage.removeItem("selectedName")
        //localStorage.removeItem("selectedNumber")
    }

    const backhOnmobileviewChat = () => {
        setMobileViewChatMessage(false);
    }

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [roots]);

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        lastMessageRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    return (

        <>
            {/* <div className="menu-button" onClick={toggleMenu}>
                <MdMenu size="2em" />
            </div>
            <div className={classList()} id="menu">
                {menu.map((item, index) => {

                    return (
                        <MenuItem
                            key={index}
                            id={index}
                            icon={item.icon}
                            text={item.text}
                            link={item.link}
                        />
                    );

                })}
                <br />
            </div>
            <div style={{ display: "flex" }}>
                <div
                    id="menu-whitespace-target"
                    hidden={isHidden}
                    onClick={hideMenu}
                    style={whiteSpaceTargetStyle}
                ></div>  <div id="menu-whitespace-target-back"
                    hidden={IsMobileView && !mobileviewChatMessage} onClick={backhOnmobileviewChat} style={backhOnmobileviewChatStyle}>back </div >
            </div>
            */}
           
            <div className="chats_main">
                <div hidden={IsMobileView }  className="chats_main_menu">
                    <SideMenu /> 
                </div>


                <div hidden={IsMobileView && mobileviewChatMessage} className="chats_main_charbar">
                    <ChatBar onPageUpdate={onPageUpdate} selectedchatbarmenu={selectedchatbarmenu} onCharBarUpdate={onCharBarUpdate} chatbarfiltermenuItems={chatbarfiltermenuItems} onSearchBar={onSearchBar} roots={roots} />
                </div>

                <div hidden={IsMobileView && !mobileviewChatMessage} className="chats_main_message">
                    <ChatBody backhOnmobileviewChat={backhOnmobileviewChat}  IsMobileView={IsMobileView}  lastMessageRef={lastMessageRef}  socket={socket} messageTextRef={messageTextRef} handlereplyclose={handlereplyclose} handlereply={handlereply} replymessage={replymessage} messages={messages} />
                </div>


                <div hidden={IsMobileView && userinfo} className="chats_main_temp">
                    <UserInfo replymessage={replymessage} messages={messages} />
                </div>




            </div>
        </>

    )
}

export default Chats;
