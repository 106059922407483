import React, { useState } from "react";
import axios from 'axios'
import "./AsyncUploadAudio.css";
const AsyncUploadAudio = ({ togglePopupAudio, socket }) => {
    var singleFileObj = [];
    var singleFileArray = [];
    const [singleFileObjValue, setSingleFileObjValue] = useState([]);
    const [singleFileArrayValue, setSingleFileArrayValue] = useState([]);
    const onImageChange = event => {
        for (let i = 0; i < event.target.files.length; i++) {
            singleFileObj.push(event.target.files[i]);
            singleFileArray.push(URL.createObjectURL(event.target.files[i]));
        }
        setSingleFileObjValue(singleFileObj);
        setSingleFileArrayValue(singleFileArray);
    };
    const shoot = async () => {
        for (let i = 0; i < singleFileObjValue.length; i++) {
            let formData = new FormData();
            formData.append('file', singleFileObjValue[i]);
            formData.append('messaging_product', 'whatsapp');
            try {
                let res = await axios.post('https://graph.facebook.com/v20.0/347188558485172/media', formData,
                    {
                        headers: {

                            'Content-Type': 'multipart/form-data',
                            'Authorization': 'Bearer EAATwRdnZAHZAYBOzcofyZBXyLLS8fRQ7Vc810wfPdqF8SbrG9kWPA0lO0kUXGS6arVnQVXLJYwAZA87Sef3TWZAbvSVyYHPRay9ZBYdaCmauTC4m8z25PczbT0JYNMJZCBaBZADUgdMNGO9Yp9C4ayuokqqTH3H5DwuvWbuz82vGLz30xWWgQpJt2ZBLriaxoTSxNwwZDZD'
                        },
                    }
                );
                if (res.status === 200) {
                    socket.emit("media",
                        {
                            to: localStorage.getItem("selectedNumber"),
                            name: localStorage.getItem("selectedName"),
                            id: res.data.id,
                            type: 'audio',
                            caption: ''
                        }
                    )
                }
            } catch (error) {
                console.error('Error uploading img : ', error);
            }
        }
        togglePopupAudio();
    }
    const removeImage = (index) => {
        setSingleFileArrayValue([
            ...singleFileArrayValue.slice(0, index),
            ...singleFileArrayValue.slice(index + 1, singleFileArrayValue.length)
        ]);

        setSingleFileObjValue([
            ...singleFileObjValue.slice(0, index),
            ...singleFileObjValue.slice(index + 1, singleFileObjValue.length)
        ]);
    };
    return (
        <div>
            <div className="asyncuploadaudioscreenheader">
                <p>Select Audio</p>
                <span onClick={togglePopupAudio}>X</span>
            </div>
            <div className="asyncuploadaudioscreensend">
                <input type="file" name="myImage" multiple accept="audio/mp3,audio/ogg" onChange={onImageChange} />
                <button onClick={shoot}>Send</button>
            </div>
            <div className="asyncuploadaudioscreen">
                {singleFileArrayValue.length !== 0 &&
                    singleFileArrayValue.map((url, index) => (
                        <div key={index} >
                            <div className="asyncuploadaudio_img_background">
                                <audio controls className="asyncuploadaudio_img_content" src={url} alt="..." key={index} />
                            </div>
                            <span className="asyncuploadaudio_remove_img" onClick={() => removeImage(index)}  >
                                X
                            </span>
                        </div>

                    ))}
            </div>
        </div>
    );
}
export default AsyncUploadAudio